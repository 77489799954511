var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-select-group",
      "hide-header": "",
      "centered": ""
    },
    on: {
      "show": _vm.refetch
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.handlerFormGroup();
            }
          }
        }, [_vm._v(" Переместить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.hideModal();
            }
          }
        }, [_vm._v(" Отмена ")])];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    staticClass: "b-form is_product_card"
  }, [_c('div', {
    staticClass: "b-form__header"
  }, [_vm._v("Группы товаров")]), _c('div', {
    staticClass: "menu-block-category-search"
  }, [_c('img', {
    staticClass: "menu-block-category-search__img",
    attrs: {
      "src": "/img/icons/products/search_category.svg",
      "alt": "search"
    }
  }), _c('b-form-input', {
    staticClass: "menu-block-category-search__input",
    attrs: {
      "value": _vm.searchCategory,
      "type": "text",
      "placeholder": "Поиск..."
    },
    on: {
      "change": _vm.setSearchCategory
    }
  }), _vm.menu_is_loading ? [_c('b-spinner', {
    staticClass: "menu-block-category-search__spiner-input",
    attrs: {
      "variant": "primary",
      "small": ""
    }
  })] : _vm._e()], 2), _c('b-overlay', {
    attrs: {
      "show": _vm.menu_is_loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "b-form__content"
  }, [_c('menu-item', {
    attrs: {
      "product_category": _vm.category,
      "items": _vm.menu_arr,
      "open_group": _vm.openGroup,
      "update_menu": _vm.groupAddedSuccessfully,
      "current_ids": _vm.current_ids,
      "is_select_group": true
    },
    on: {
      "selected_group": _vm.selectedGroup
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }