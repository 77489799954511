<template>
  <b-modal
    id="modal-select-group"
    hide-header
    centered
    @show="refetch"
  >
    <b-form class="b-form is_product_card">
      <div class="b-form__header">Группы товаров</div>
      <div class="menu-block-category-search">
        <img
          class="menu-block-category-search__img"
          src="/img/icons/products/search_category.svg"
          alt="search"
        />
        <b-form-input
          :value="searchCategory"
          class="menu-block-category-search__input"
          type="text"
          placeholder="Поиск..."
          @change="setSearchCategory"
        />
        <template v-if="menu_is_loading">
          <b-spinner
            class="menu-block-category-search__spiner-input"
            variant="primary"
            small
          />
        </template>
      </div>
      <b-overlay
        :show="menu_is_loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
      >
        <div class="b-form__content">
          <menu-item
            :product_category="category"
            :items="menu_arr"
            :open_group="openGroup"
            :update_menu="groupAddedSuccessfully"
            :current_ids="current_ids"
            :is_select_group="true"
            @selected_group="selectedGroup"
          />
        </div>
      </b-overlay>
    </b-form>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="handlerFormGroup()"
      >
        Переместить
      </b-button>
      <b-button
        variant="outline-primary"
        @click="hideModal()"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import MenuItem from '../components/menu/MenuItem.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      MenuItem
    },

    apollo: {
      ReadCategoryByPath: {
        query: require('../gql/getCategoryPath.graphql'),
        fetchPolicy: 'no-cache',
        skip() {
          return this.searchCategory
        },
        variables() {
          return {
            path: this.current_category?.path?.filter((el) => el) ?? []
          }
        },
        async result({ data }) {
          if (data?.ReadCategoryByPath) {
            this.menu_arr = data?.ReadCategoryByPath ?? []
            this.menu_is_loading = false
          }
          // const categories = data?.Categories ?? []
          // if (!this.current_category && categories?.length)
          //   this.setCurrentCategory(categories[0])
          // this.menu_arr = await this.updateCategories(
          //   categories,
          //   this.current_category_path
          // )
          //
          // if (this.isCreatedNewGroup) {
          //   this.$apollo.queries.Category.skip = false
          //   this.$apollo.queries.Category.refetch()
          // }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
        // watchLoading(isLoading) {
        //   this.menu_is_loading = isLoading
        // }
      },

      SearchCategory: {
        query: require('../gql/SearchCategory.gql'),
        fetchPolicy: 'no-cache',
        debounce: 500,
        skip() {
          return !this.searchCategory
        },
        variables() {
          return {
            search: this.searchCategory
          }
        },
        async result({ data }) {
          if (data) {
            this.menu_arr = data?.SearchCategory ?? []
            this.menu_is_loading = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    props: {
      modal_show: {
        type: Boolean,
        default: true
      },
      category: Object,
      set_category: Function
    },

    data: () => ({
      searchCategory: '',
      menu_is_loading: true,
      menu_arr: [],
      selected_category: null
    }),

    computed: {
      ...mapGetters({
        current_ids: 'products/getCurrentIds'
      })
    },

    destroyed() {
      this.setCurrentsIds([])
    },

    methods: {
      ...mapActions({
        setCurrentCategory: 'products/setCurrentCategory',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setCurrentsIds: 'products/setCurrentIds'
      }),

      async setSearchCategory(newVal) {
        this.menu_is_loading = true
        this.searchCategory = newVal
      },

      clearSearchCategory() {
        this.searchCategory = ''
      },

      refetch() {
        this.$apollo.queries.ReadCategoryByPath.refetch()
      },

      hideModal() {
        this.$bvModal.hide('modal-select-group')
      },

      showModal() {
        this.$bvModal.show('modal-select-group')
      },

      handlerFormGroup() {
        if (this.set_category) this.set_category(this.selected_category)
        if (!this.set_category) this.setCurrentCategory(this.selected_category)
        this.hideModal()
      },

      addChild(items) {
        this.menu_arr = this.recursionMenu(this.menu_arr, items)

        if (this.getItemsIds(this.current_category_path).includes(items.id)) {
          this.setCurrentCategoryPath(this.getCurrentCategoriesPath(this.current_category_path, items))
        } else {
          this.setCurrentCategoryPath(items)
        }
      },

      recursionMenu(items, childs) {
        const new_items = JSON.parse(JSON.stringify(items))

        return new_items?.map((item) => {
          if (item.id === childs.id) {
            item.child_groups = childs.child_groups?.map((child) => {
              if (child.child_groups) child.child_groups = null

              return child
            })
          } else if (item.child_groups) {
            item.child_groups = this.recursionMenu(item.child_groups, childs)
          }

          return item
        })
      },

      getItemsIds(items, curId = null) {
        let result = []

        if (items?.id) {
          result.push(items.id)
          if (items.id !== curId && items?.child_groups?.length) {
            items.child_groups.forEach((t) => {
              result = [...result, ...this.getItemsIds(t)]
            })
          }
        }
        return result
      },

      openGroup(item) {
        if (JSON.stringify(item.path) == JSON.stringify(this.current_ids)) {
          this.setCurrentsIds((item?.path ?? []).slice(0, -1))
          return
        }
        if (item.path?.every((el) => el === item.id) && this.current_ids.includes(item.id)) {
          this.setCurrentsIds([])
          return
        }
        this.setCurrentsIds(item.path)
        this.$apollo.queries.ReadCategoryByPath.refetch({
          path: item.path
        })
      },

      updateCategories(categories, current) {
        const updateCategories = [...categories]

        if (current) {
          updateCategories.forEach((category, index) => {
            if (category.id === current.id) {
              updateCategories[index] = current
            }
          })
        }

        return updateCategories
      },

      groupAddedSuccessfully() {
        this.$apollo.queries.Categories.refetch()
      },

      selectedGroup(category) {
        this.selected_category = category
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .b-form__content {
    max-height: 555px !important;
  }

  :deep .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 650px !important;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 22px;
    overflow: hidden;
  }
  .menu-block-category-search {
    width: 100%;
    position: relative;
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 10px;
    margin-bottom: 5px;

    &__input {
      padding-left: 35px;
      padding-right: 35px;
    }

    &__spiner-input {
      position: absolute;
      top: 9px;
      right: 25px;
      cursor: pointer;
    }

    &__img {
      position: absolute;
      top: 8px;
      left: 25px;
    }
  }

  ::v-deep() {
    .modal-content {
      padding: 0 !important;
    }

    .modal-body {
      padding: 0;

      .is_product_card {
        .menu {
          &.selected {
            & > .elm-buttont {
              position: relative;

              &:before {
                position: absolute;
                content: '';
                top: -4px;
                bottom: -4px;
                right: -50px;
                left: -200px;
                background: #e6f0ff;
                z-index: 0;
              }
            }
          }
        }
      }
    }

    .modal-footer {
      padding: 8px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      justify-content: flex-start;
      margin-top: 0;

      button {
        width: auto;
        margin: 0 8px 0 0;
      }
    }
  }
  #modal-select-group {
    .modal-body {
      .b-form {
        &__header {
          font-weight: 500;
          font-size: 18px;
          color: #313131;
          border-bottom: 1px solid #e1e1e1;
          padding: 12px 15px;
        }

        &__content {
          max-height: 600px;
          overflow-y: auto;
          overflow-x: hidden;
          padding-bottom: 15px;
          position: relative;
        }
      }

      .is_product_card {
        .menu {
          &.selected {
            & > .elm-buttont {
              position: relative;

              &:before {
                position: absolute;
                content: '';
                top: -4px;
                bottom: -4px;
                right: -50px;
                left: -200px;
                background: #e6f0ff;
                z-index: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
